<template>
    <div>
        <b-container>
            <h3 class="text-center">Login</h3>
        <b-form v-model="valid" @submit="submit">
            <b-alert v-if="error" variant="warning" show>
                {{ error}}
            </b-alert>
            <b-form-group
                          label="Email:"
                          label-for="mail">
                <b-form-input id="mail"
                              type="email"
                              required
                              v-model="email"
                              placeholder="Enter your email address">
                </b-form-input>
            </b-form-group>
            <b-form-group
                          label="Password:"
                          label-for="pw">
                <b-form-input id="pw"
                              type="password"
                              required
                              v-model="password"
                              placeholder="Enter your password">
                </b-form-input>
            </b-form-group>
            <b-button type="submit" :disabled="!valid" variant="primary">Login</b-button>
        </b-form>
        </b-container>
    </div>
</template>

<script>
    import api from '../api/Base'
    export default {
        name: "login"
    ,
        data() {
            return {
                email: '',
                password: '',
                valid: true,
                error: null,
            }
        },
        methods: {
            submit (e) {
                e.preventDefault()
                this.error = null
                api.login(this.email, this.password).then(() => {
                    this.$router.push('/')
                }).catch( () => {
                    this.error = "Something went wrong. Check your email address and password again!"
                })
            }
        }
    }
</script>

<style scoped>

</style>