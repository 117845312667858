import base from './Base'

export default {
    getBundles(applicationId) {
        const path = `/admin/applications/${applicationId}/bundles`;
        return base.get(path, {})
    },
    getBundle(applicationId, bundleId) {
        const path = `/admin/applications/${applicationId}/bundles/${bundleId}`;
        return base.get(path, {})
    },
    createBundle(applicationId, value) {
        const path = `/admin/applications/${applicationId}/bundles`;
        return base.post(path, {
            value: value
        })
    },
    editBundle(applicationId, bundleId, value) {
        const path = `/admin/applications/${applicationId}/bundles/${bundleId}`;
        return base.patch(path, {
            value: value
        })
    },
    deleteBundle(applicationId, bundleId) {
        const path = `/admin/applications/${applicationId}/bundles/${bundleId}`;
        return base.delete(path, {})
    }
}