<template>
    <form>
        <h2>App Name</h2>
        <b-container>
            <b-row>
                <b-col cols="9">
                    <b-form-input id="app_name"
                                  v-model="app.name"
                                  placeholder="Enter app name"></b-form-input>

                </b-col>
                <b-col cols="3">
                    <b-button variant="primary" @click="editApp">Edit</b-button>
                    <b-button variant="danger" @click="deleteApp">Delete App</b-button>
                </b-col>
            </b-row>
        </b-container>

        <h2>Bundles</h2>
        <b-container>
            <div v-for="bundle in bundles">
                <b-row>
                    <b-col cols="9">
                        <b-form-input
                                type="text"
                                v-model="bundle.value"
                                placeholder="Enter bundle name"></b-form-input>
                    </b-col>
                    <b-col cols="3">
                        <b-button variant="primary" @click="editBundle(bundle)">Edit</b-button>
                        <b-button variant="danger" @click="deleteBundle(bundle.id)">Delete Bundle</b-button>
                    </b-col>
                </b-row>
            </div>
            <br>
            <b-row>
                <b-col cols="9">
                    <b-form-input
                            type="text"
                            v-model="bundleValues[appId]"
                            placeholder="Enter bundle name"></b-form-input>
                </b-col>
                <b-col cols="3">
                    <b-button variant="success" @click="createBundle">Add Bundle</b-button>
                </b-col>
            </b-row>
        </b-container>
        <Documents/>
    </form>
</template>

<script>
    import appAPI from '../api/Applications'
    import bundleAPI from '../api/Bundles'
    import Documents from './Documents'

    export default {
        name: "app-details",
        data() {
            return {
                appId: '',
                app: '',
                bundles: '',
                bundleValues: [],
            }
        },
        components: {
            Documents
        },
        watch: {
            '$route'(to, from) {
                this.appId = this.$route.params.id;
            },
            appId() {
                this.appId = this.$route.params.id;
                this.refresh()
            }
        },
        mounted() {
            this.appId = this.$route.params.id;
        },

        methods: {
            refresh() {
                appAPI.getApplication(this.appId).then(app => {
                    this.app = app;
                });
                bundleAPI.getBundles(this.appId).then(bundles => {
                    this.bundles = bundles
                });
            },
            editApp() {
                appAPI.editApplication(this.appId, this.app.name).then(() => {
                    this.refresh()
                })
            },
            deleteApp() {
                appAPI.deleteApplication(this.appId).then(() => {
                    this.$router.push('/')
                })
            },

            createBundle() {
                bundleAPI.createBundle(this.appId, this.bundleValues[this.appId]).then(() => {
                    this.refresh()
                    this.bundleValues[this.appId] = ''
                })
            },
            editBundle(bundle) {
                bundleAPI.editBundle(this.appId, bundle.id, bundle.value).then(() => {
                    this.refresh()
                })
            },
            deleteBundle(bundleId) {
                bundleAPI.deleteBundle(this.appId, bundleId).then(() => {
                    this.refresh()
                })
            },


        },
    }
</script>

<style>


</style>