import base from './Base'

export default {
    getTranslations(applicationId, documentId) {
        const path = `/admin/applications/${applicationId}/documents/${documentId}/translations`;
        return base.get(path, {})
    },
    getTranslation(applicationId, documentId, translationId) {
        const path = `/admin/applications/${applicationId}/documents/${documentId}/translations/${translationId}`;
        return base.get(path, {})
    },
    createTranslation(applicationId, documentId, language, content) {
        const path = `/admin/applications/${applicationId}/documents/${documentId}/translations`;
        return base.post(path, {
            language: language,
            content: content,
        })
    },
    editTranslation(applicationId, documentId, translationId, language, content) {
        const path = `/admin/applications/${applicationId}/documents/${documentId}/translations/${translationId}`;
        return base.patch(path, {
            language: language,
            content: content,
        })
    },
    deleteTranslation(applicationId, documentId, translationId) {
        const path = `/admin/applications/${applicationId}/documents/${documentId}/translations/${translationId}`;
        return base.delete(path, {})
    }
}