<template>
    <div class="document">

        <b-container>
            <div class="doc-container">
                <b-tabs>
                    <b-tab title="HTML" active>
                        <br>
                        <b-row>
                            <b-col cols="1"><p>Version: </p></b-col>
                            <b-col cols="8">
                                <b-form-input
                                        type="text"
                                        v-model="doc.version"
                                        placeholder="Enter Version"></b-form-input>
                            </b-col>
                            <b-col cols="3">
                                <b-button variant="primary" @click="editVersion">Edit version</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="9">
                                <b-form-textarea id="html"
                                                 placeholder="Enter Text"
                                                 v-model="doc.content"
                                                 :rows="3"
                                                 :max-rows="10">
                                </b-form-textarea>
                            </b-col>
                            <b-col cols="3">
                                <b-button variant="primary" @click="editDocContent">Edit Text</b-button>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Preview">
                        <br>
                        <b-card>
                            <b-card-body style="max-height:310px; min-height:130px; overflow-y:scroll">
                                <div v-html="doc.content">
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab title="Links">
                        <b-table :items="linkItems">
                            <template slot="link" slot-scope="data">
                                <a :href="data.value">{{data.value}}</a>
                            </template>
                        </b-table>
                    </b-tab>
                </b-tabs>
                <b-button class="text-capitalize" variant="danger" @click="deleteDocument">Delete {{doc.type}}</b-button>
            </div>

            <h4>Translations </h4>
            <div v-if="docTrans.length !== 0">
                <div v-for="trans in docTrans">
                    <div class="trans-container">
                        <b-tabs>
                            <b-tab title="HTML">
                                <br>
                                <b-row>
                                    <b-col cols="9">
                                        <b-form-input
                                                type="text"
                                                v-model="trans.language"
                                                placeholder="Enter Language"></b-form-input>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-button variant="primary" @click="editLanguage(trans)">Edit</b-button>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="9">
                                        <b-form-textarea type="text"
                                                         v-model="trans.content"
                                                         placeholder="Enter Translation Text"
                                                         :rows="3"
                                                         :max-rows="10"
                                        >
                                        </b-form-textarea>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-button variant="primary" @click="editTransContent(trans)">Edit</b-button>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Preview">
                                <br>
                                <b-card no-body>
                                    <b-card-body style="max-height:310px; min-height:130px; overflow-y:scroll">
                                        <div v-html="trans.content">
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-tab>
                        </b-tabs>
                        <b-button variant="danger" @click="deleteTranslation(trans.id)">Delete {{trans.value}}
                            Translation
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="newTrans">
                <b-tabs>
                    <b-tab title="HTML">
                        <br>
                        <b-row>
                            <b-col cols="9">
                                <b-form-input
                                        type="text"
                                        v-model="newLanguage"
                                        placeholder="Enter language"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="9">
                                <b-form-textarea id="agbTrans"
                                                 placeholder="Enter Translation Text"
                                                 v-model="newTransContent"
                                                 :rows="3"
                                                 :max-rows="10">
                                </b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-button variant="success" @click="createTranslation">Add Translation</b-button>
                    </b-tab>
                    <b-tab title="Preview">
                        <br>
                        <b-card no-body>
                            <b-card-body style="max-height:310px; min-height:130px; overflow-y:scroll">
                                <div v-html="newTransContent">
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
        </b-container>
    </div>
</template>

<script>
    import docAPI from '../api/Documents'
    import transAPI from '../api/Translations'

    export default {
        name: "document",
        data() {
            return {
                newLanguage: '',
                docTrans: [],
                newTransContent: '',
                tempValue: '',

            }
        },
        props: [
            'appId',
            'doc',
            'translations',
            'onDocChange'
        ],
        methods: {
            createTranslation() {
                transAPI.createTranslation(this.appId, this.doc.id, this.newLanguage, this.newTransContent).then(() => {
                    this.onDocChange()
                })
            },
            deleteTranslation(transId) {
                transAPI.deleteTranslation(this.appId, this.doc.id, transId).then(() => {
                    this.onDocChange()
                })
            },
            editLanguage(trans) {
                transAPI.editTranslation(this.appId, this.doc.id, trans.id, trans.language, trans.content).then(() => {
                    this.onDocChange()
                })
            },
            editTransContent(trans) {
                transAPI.editTranslation(this.appId, this.doc.id, trans.id, trans.value, trans.content).then(() => {
                    this.onDocChange()
                })
            },

            deleteDocument() {
                docAPI.deleteDocument(this.appId, this.doc.id).then(() => {
                    this.onDocChange()
                })
            },
            editVersion() {
                docAPI.editDocument(this.appId, this.doc.id, this.doc.content, this.doc.version, this.doc.type, this.docTrans).then(() => {
                    this.onDocChange()
                })
            },
            editDocContent() {
                docAPI.editDocument(this.appId, this.doc.id, this.doc.content, this.doc.version, this.doc.type, this.docTrans).then(() => {
                    this.onDocChange()
                })
            }
        },
        computed: {
          linkItems() {
              const getUrl = window.location;
              const baseurl = getUrl.protocol + "//" + getUrl.host ;
              let baseArray = [{
                  language: 'Default',
                  link: `${baseurl}/#/document/${this.appId}/${this.doc.type}/default`
              }]

              for (let lang of this.translations) {
                  baseArray.push({
                      language: lang.language,
                      link: `${baseurl}/#/document/${this.appId}/${this.doc.type}/${lang.language}`
                  })
              }
              return baseArray
          }
        },
        watch: {
            translations() {
                this.docTrans = this.translations
            }
        },
    }
</script>

<style scoped>
    .document {
        margin-bottom: 50px
    }

    .newTrans {
        padding-top: 30px
    }

    .doc-container {
        padding-bottom: 40px
    }

    .trans-container {
        padding-bottom: 30px
    }
</style>