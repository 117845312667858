<template>
    <div id="app">
        <div class="row pb-0">
            <div class="col-10">
                <div class="row">
                    <img class="img-fluid pl-4" src="./assets/logo_mobivention.svg" style="width: 160px"/>
                    <h1 class="px-5 pt-3" v-if="!hideHeader">AGB Verwaltung</h1>
                </div>
            </div>
            <div v-if="loggedIn" class="col-2 px-5 pt-3">
                <b-button @click="logout">Logout</b-button>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-3 p-4" v-if="loggedIn && !hideHeader">
                <AppsMenu/>
            </div>
            <div class="col-12">
                <router-view/>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>
    import AppsMenu from '././components/AppsMenu.vue'

    export default {
        name: 'App',
        data() {
            return {
                loggedIn: false,
                hideHeader: false
            }
        },
        components: {
            AppsMenu
        },
        mounted() {
            this.hideHeader = this.$route.meta.hideHeader === true
            if (localStorage.getItem("token") !== null) {
                this.loggedIn = true
            }
        },
        methods: {
            logout() {
                localStorage.clear()
                this.loggedIn = false
                this.$router.push('/login')
            },
        },
        watch: {
            '$route'(to, from) {
                this.hideHeader = this.$route.meta.hideHeader === true
                if (localStorage.getItem("token") !== null) {
                    this.loggedIn = true
                }
            },

        }
    }
</script>

<style>
    #app {
        margin: 20px;
    }

    .container {
        padding-bottom: 30px;
    }

    .row {
        padding-bottom: 10px;
    }

    .card {
        margin-bottom: 10px;
    }

</style>

