/* @flow */
import axios from 'axios'

const baseURL = ""
export default {

    login(user, password) {
        return axios({
            method: 'post',
            baseURL: baseURL,
            url: 'login',
            auth: {
                username: user,
                password: password
            },
            responseType: 'json'
        }).then(resp => {
            localStorage.setItem("token", resp.data.token)
            return true
        })
    },

    getPublic(path, params) {
        return axios({
            method: 'get',
            baseURL: baseURL,
            url: path,
            data: params,
        }).then(resp => {
            return resp.data
        })
    },

    get (path, params) {
        return axios({
            method: 'get',
            baseURL: baseURL,
            url: path,
            data: params,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        }).then(resp => {
            return resp.data
        })
    },
    post (path, params) {
        return axios({
            method: 'post',
            baseURL: baseURL,
            url: path,
            data: params,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        })
    },
    delete (path, params){
        return axios({
            method: 'delete',
            baseURL: baseURL,
            url: path,
            data:params,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        })
    },
    patch (path, params) {
        return axios({
            method: 'patch',
            baseURL: baseURL,
            url: path,
            data: params,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        })
    },
}