<template>
    <div class="document">
        <b-container>
            <b-tabs>
                <b-tab title="HTML" active>
                    <br>
                    <b-row>
                        <b-col cols="1"><p>Version: </p></b-col>
                        <b-col cols="8">
                            <b-form-input
                                    type="text"
                                    v-model="newVersion"
                                    placeholder="Enter Version"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="9">
                            <b-form-textarea id="html"
                                             v-model="newDocContent"
                                             placeholder="Enter Text"
                                             :rows="3"
                                             :max-rows="10">
                            </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab title="Preview">
                    <br>
                    <b-card no-body>
                        <b-card-body style="max-height:310px; min-height:130px; overflow-y:scroll">
                            <div v-html="newDocContent">
                            </div>
                        </b-card-body>
                    </b-card>
                </b-tab>
            </b-tabs>
            <b-button class="text-capitalize" variant="success" @click="createDocument">Add {{docType}}</b-button>
        </b-container>
    </div>
</template>

<script>
    import docAPI from '../api/Documents'

    export default {
        name: "addDocument",
        props: [
            'app',
            'docType',
            'onDocChange'
        ],
        data() {
            return {
                newVersion: '',
                newDocContent: ''
            }
        },
        methods: {
            createDocument() {
                docAPI.createDocument(this.app, this.newDocContent, this.newVersion, this.docType, '').then(() => {
                    this.onDocChange()
                })
            },
        }
    }
</script>

<style scoped>
    .document {
        margin-bottom: 50px
    }
</style>