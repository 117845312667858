import Vue from 'vue'
import Router from 'vue-router'
import AppDetails from '@/components/AppDetails'
import Login from '@/components/Login'
import ExternalDocument from "@/components/ExternalDocument";

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/document/:appId/:docType/:language?',
            name: 'Document',
            component: ExternalDocument,
            meta: {
                hideHeader: true
            }
        },
        {
            path: '/details/:id',
            name: 'AppDetails',
            component: AppDetails
        },

    ]
});

router.beforeEach((to, from, next) => {
    const publicRoutes = ['Login', 'Document'];
    if (publicRoutes.includes(to.name) === false && localStorage.getItem("token")===null && from.name !== 'Login') {
        next('/login')
    } else {
        next()
    }
});

export default router