import base from './Base'

export default {
    getApplications() {
        return base.get('admin/applications', {})
    },
    getApplication(applicationId) {
        const path = `/admin/applications/${applicationId}`;
        return base.get(path, {})
    },
    createApplication(name) {
        return base.post('admin/applications', {
            name: name
        })
    },
    editApplication(applicationId, name) {
        const path = `/admin/applications/${applicationId}`;
        return base.patch(path, {
            name: name
        })
    },
    deleteApplication(applicationId) {
        const path = `/admin/applications/${applicationId}`;
        return base.delete(path, {})
    }
}