<template>
    <div>
        <h2>Documents</h2>
        <h3 class>AGB</h3>
        <div v-if="agbDoc!==''">
            <Document :doc="agbDoc" :translations="agbTranslations" :appId="appId" :on-doc-change="refresh"/>
        </div>
        <div v-else>
            <AddDocument :app="app" docType="agb" :on-doc-change="refresh"/>
        </div>

        <h3 class>Privacy</h3>
        <div v-if="privacyDoc!==''">
            <Document :doc="privacyDoc" :translations="privacyTranslations" :appId="appId" :on-doc-change="refresh"/>
        </div>
        <div v-else>
            <AddDocument :app="app" docType="privacy" :on-doc-change="refresh"/>
        </div>

        <h3 class>FAQ</h3>
        <div v-if="faqDoc!==''">
            <Document :doc="faqDoc" :translations="faqTranslations" :appId="appId" :on-doc-change="refresh"/>
        </div>
        <div v-else>
            <AddDocument :app="app" docType="faq" :on-doc-change="refresh"/>
        </div>

        <h3 class>Impressum</h3>
        <div v-if="imprintDoc!==''">
            <Document :doc="imprintDoc" :translations="imprintTranslations" :appId="appId" :on-doc-change="refresh"/>
        </div>
        <div v-else>
            <AddDocument :app="app" docType="impressum" :on-doc-change="refresh"/>
        </div>

    </div>
</template>

<script>
    import appAPI from '../api/Applications'
    import docAPI from '../api/Documents'
    import transAPI from '../api/Translations'
    import Document from './Document'
    import AddDocument from './AddDocument'

    export default {
        name: "documents",
        data() {
            return {
                app: '',
                appId: '',
                agbTranslations: '',
                privacyTranslations: '',
                faqTranslations: '',
                imprintTranslations: '',
                agbDoc: '',
                privacyDoc: '',
                faqDoc: '',
                imprintDoc: '',
            }
        },
        components: {
            Document,
            AddDocument
        },
        watch: {
            '$route'(to, from) {
                this.appId = this.$route.params.id;
            },
            appId() {
                this.appId = this.$route.params.id;
                this.refresh()
            }
        },
        mounted() {
            this.appId = this.$route.params.id;
        },
        methods: {
            refresh(){
                appAPI.getApplication(this.appId).then(app => {
                    this.app = app;
                });
                this.agbDoc = '';
                this.privacyDoc = '';
                this.faqDoc = '';
                this.imprintDoc = '';
                docAPI.getCurrentDocuments(this.appId).then(docs => {
                    docs.forEach(doc => {
                        if (doc.type === ("agb")) {
                            this.agbDoc = doc;
                        }
                        else if (doc.type === ("privacy")) {
                            this.privacyDoc = doc;
                        }
                        else if (doc.type === ("faq")) {
                            this.faqDoc = doc;
                        }
                        else if (doc.type === ("impressum")) {
                            this.imprintDoc = doc;
                        }
                    });

                    if (this.agbDoc !== '') {
                        transAPI.getTranslations(this.appId, this.agbDoc.id).then(trans => {
                            this.agbTranslations = trans
                        });
                    }

                    if (this.privacyDoc !== '') {
                        transAPI.getTranslations(this.appId, this.privacyDoc.id).then(trans => {
                            this.privacyTranslations = trans
                        });
                    }
                    if (this.faqDoc !== '') {
                        transAPI.getTranslations(this.appId, this.faqDoc.id).then(trans => {
                            this.faqTranslations = trans
                        });
                    }
                    if (this.imprintDoc !== '') {
                        transAPI.getTranslations(this.appId, this.imprintDoc.id).then(trans => {
                            this.imprintTranslations = trans
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    h3 {
        padding-bottom: 10px;
    }
</style>