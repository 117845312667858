import base from './Base'

export default {

    getPublicDocument(applicationId, docType, language) {
        let path = `/api/document/${applicationId}/${docType}`;
        if (language) {
            path = `/api/document/${applicationId}/${docType}/${language}`;
        }
        return base.getPublic(path, {})
    },

    getDocuments(applicationId) {
        const path = `/admin/applications/${applicationId}/documents`;
        return base.get(path, {})
    },
    getCurrentDocuments(applicationId) {
        const path = `/admin/applications/${applicationId}/documents/current`;
        return base.get(path, {})
    },
    getDocument(applicationId, documentId) {
        const path = `/admin/applications/${applicationId}/documents/${documentId}`;
        return base.get(path, {})
    },
    createDocument(application, content, version, type, translations) {
        const path = `/admin/applications/${application.id}/documents`;
        return base.post(path, {
            content: content,
            version: version,
            type: type,
            translations: translations,
            application: application
        })
    },
    editDocument(applicationId, documentId, content, version, type, translations) {
        const path = `/admin/applications/${applicationId}/documents/${documentId}`;
        return base.patch(path, {
            content: content,
            version: version,
            type: type,
            translations: translations,
        })
    },
    deleteDocument(applicationId, documentId) {
        const path = `/admin/applications/${applicationId}/documents/${documentId}`;
        return base.delete(path, {})
    }
}