<template>
    <div>
        <b-row class="pb-3">
            <b-col cols="7">
                <b-form-input
                        type="text"
                        v-model="newApp"
                        placeholder="Enter app name"></b-form-input>
            </b-col>
            <b-col cols="5">
                <b-button variant="success" @click="createApp">Add Application</b-button>
            </b-col>
        </b-row>
        <hr/>
        <div class="nav flex-column nav-pills" v-for="app in apps" id="myTab" role="tablist"
             aria-orientation="vertical">
            <li class="nav-item">
                <b-link class="nav-link" :to="{path: `/details/${app.id}`, params: {appId: app.id}}"
                        v-text="app.name"
                active-class="active"></b-link>
            </li>
        </div>
    </div>
</template>

<script>
    import appAPI from '../api/Applications'

    export default {
        name: "apps-menu",
        data() {
            return {
                apps: [],
                newApp: '',
            }
        },
        mounted() {
            appAPI.getApplications().then(apps => {
                this.apps = apps;
            })
        },
        methods: {
            createApp() {
                appAPI.createApplication(this.newApp).then(() => {
                    this.refreshApps()
                });
                this.newApp = '';
            },
            refreshApps() {
                appAPI.getApplications().then(apps => {
                    this.apps = apps;
                })
            }
        },
        watch: {

        },
    }
</script>

<style scoped>

</style>