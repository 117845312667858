<template>
    <div>
        <div v-html="content"></div>
    </div>
</template>

<script>
    import docAPI from '../api/Documents'
    import bundleAPI from '../api/Bundles'
    import Documents from './Documents'

    export default {
        name: "external-document",
        data() {
            return {
                appId: '',
                docType: '',
                language: '',
                content: ''
            }
        },
        mounted() {
            this.appId = this.$route.params.appId;
            this.docType = this.$route.params.docType;
            this.language = this.$route.params.language;
            docAPI.getPublicDocument(this.appId, this.docType, this.language).then(d => {
                this.content = d
            });
        },
        watch: {
            '$route'(to, from) {
                this.appId = this.$route.params.appId;
                this.docType = this.$route.params.docType;
                this.language = this.$route.params.language;
                docAPI.getPublicDocument(this.appId, this.docType, this.language).then(d => {
                    this.content = d
                });
            },

        },
    }
</script>